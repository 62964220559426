<template>
  <div id="main">
    <div id="banner">
      <swiper ref="swiper" :datas="banner"></swiper>
    </div>
    <div id="detail">
      <div class="detail-item text-left wrightin-t20">
        <p class="title">精准治疗 紧致升级</p>
        <p class="subTitle">创新传统盆底修复</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">澜渟T20·新一代 盆底肌修复仪</p>
        <p class="subDesc">双重修复 紧致系统</p>
        <button class="more" @click='goProduct("t20")'>了解更多</button>
        <img class="img" :src='require("@/assets/img/home/detail-t20.png")'>
      </div>
      <div class="detail-item text-right wrightin-luolan">
        <p class="title">三维度医养护</p>
        <p class="subTitle">呵护产后妈妈的蜕变</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">洛澜盆底康复治疗仪</p>
        <p class="subDesc">多维修复 医疗级诊疗方案</p>
        <button class="more" @click='goProduct("luolan")'>了解更多</button>
        <img class="img" :src='require("@/assets/img/home/detail-luolan.png")'>
      </div>
      <div class="detail-item text-left wrightin-mulan">
        <p class="title">化繁为简 一机双用</p>
        <p class="subTitle">强韧盆底 重塑腹肌曲线</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">沐澜盆底康复仪</p>
        <p class="subDesc">盆底肌+腹直肌 一机双修复</p>
        <button class="more" @click='goProduct("mulan")'>了解更多</button>
        <img class="img" :src='require("@/assets/img/home/detail-mulan.png")'>
      </div>
      <div class="detail-item text-right wrightin-two">
        <p class="title">盆底抗衰神器</p>
        <p class="subTitle">让宝妈重拾少女紧致</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">T20 Max 盆底肌修复仪</p>
        <p class="subDesc">足不出户唤醒盆底肌肉</p>
        <button class="more" @click='goProduct("wrightinTwo")'>了解更多</button>
        <img class="img" :src='require("@/assets/img/home/detail-wrightinTwo.png")'>
      </div>
      <div class="detail-item text-left wrightin">
        <p class="title">盆底黑科技</p>
        <p class="subTitle">只属于你的贴身盆底康复师</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">初澜盆底生物刺激反馈仪</p>
        <p class="subDesc">在家治疗盆底肌肉功能障碍</p>
        <button class="more" @click='goProduct("wrightin")'>了解更多</button>
        <img class="img" :src='require("@/assets/img/home/detail-wrightin.png")'>
      </div>
      <div class="detail-item text-right kegel">
        <p class="title">5球组合出击</p>
        <p class="subTitle">感受紧致魅力</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">盆底肌肉康复器</p>
        <p class="subDesc">凯格尔球</p>
        <button class="more" @click='goProduct("kegel")'>了解更多</button>
        <img class="img" v-lazy='require("@/assets/img/home/detail-kegel.png")'>
      </div>
      <div class="detail-item text-left wireless">
        <p class="title">健康塑形新主张</p>
        <p class="subTitle">让妈妈大胆炫腹</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">无线产后恢复仪</p>
        <p class="subDesc">躺着告别大腹婆</p>
        <button class="more" @click='goProduct("wireless")'>了解更多</button>
        <img class="img" v-lazy='require("@/assets/img/home/detail-wireless.png")'>
      </div>
      <div class="detail-item app">
        <p class="title">澜渟智能服务平台</p>
        <p class="subTitle">家用盆底康复软件</p>
        <div class="vi-symbol symbol"></div>
        <p class="desc">两大盆底App</p>
        <p class="subDesc">助力盆底康复行业智慧化升级</p>
        <button class="more" @click="goApp()">了解更多</button>
        <p class="user">
          <img v-lazy='require("@/assets/img/home/detail-app-user.png")'>
          <span>澜渟用户版</span>
          <em>凯格尔训练指导</em>
        </p>
        <p class="doctor">
          <img v-lazy='require("@/assets/img/home/detail-app-doctor.png")'>
          <span>澜渟医生版</span>
          <em>医生进阶学习</em>
        </p>
      </div>
    </div>
    <div id="server">
      <p class="title">家庭盆底解决方案服务商</p>
      <div class="server-content">
        <div class="server-item">
          <strong>34<em>个</em></strong>
          <span>省、自治区和直辖市市场</span>
        </div>
        <div class="server-item">
          <strong>10<em>年+</em></strong>
          <span>盆底领域经验</span>
        </div>
        <div class="server-item">
          <strong>150<em>w+</em></strong>
          <span>APP终端用户</span>
        </div>
        <div class="server-item">
          <strong>10000<em>+</em></strong>
          <span>全国医疗产康机构</span>
        </div>
        <div class="server-item">
          <strong>100<em>+</em></strong>
          <span>知识产权</span>
        </div>
      </div>
    </div>
    <div id="advantage">
      <img class="bg" v-lazy='require("@/assets/img/home/advantage-bg.png")'>
      <span class="title">公司优势</span>
      <div class="content">
        <div class="item quality" :style='{top: quality.top+"rem"}' @mouseenter="enterAdvantageQuality()"
          @mouseleave="leaveAdvantageQuality()">
          <img class="bg" v-show="quality.hover" :src='require("@/assets/img/home/advantage-quality-bg.png")'>
          <img class="logo" v-show="!quality.hover" :src='require("@/assets/img/home/advantage-quality-logo.png")'>
          <img class="logo" v-show="quality.hover" :src='require("@/assets/img/home/advantage-quality-logo-hover.png")'>
          <span class="text">品质</span>
          <span class="subText">追求一流的产品和服务，对品质把关严苛，经过100万+用户实际考验，品质不凡</span>
        </div>
        <div class="item technology" :style='{top: technology.top+"rem"}' @mouseenter="enterAdvantageTechnology()"
          @mouseleave="leaveAdvantageTechnology()">
          <img class="bg" v-show="technology.hover" :src='require("@/assets/img/home/advantage-technology-bg.png")'>
          <img class="logo" v-show="!technology.hover"
            :src='require("@/assets/img/home/advantage-technology-logo.png")'>
          <img class="logo" v-show="technology.hover"
            :src='require("@/assets/img/home/advantage-technology-logo-hover.png")'>
          <span class="text">技术</span>
          <span class="subText">经验丰富的技术研发团队，强大的自主研发实力，持有多项专利</span>
        </div>
        <div class="item service" :style='{top: service.top+"rem"}' @mouseenter="enterAdvantageService()"
          @mouseleave="leaveAdvantageService()">
          <img class="bg" v-show="service.hover" :src='require("@/assets/img/home/advantage-service-bg.png")'>
          <img class="logo" v-show="!service.hover" :src='require("@/assets/img/home/advantage-service-logo.png")'>
          <img class="logo" v-show="service.hover" :src='require("@/assets/img/home/advantage-service-logo-hover.png")'>
          <span class="text">服务</span>
          <span class="subText">拥有一支盆底领域人才团队，造就优质营销培训体系和服务体系</span>
        </div>
        <div class="item trust" :style='{top: trust.top+"rem"}' @mouseenter="enterAdvantageTrust()"
          @mouseleave="leaveAdvantageTrust()">
          <img class="bg" v-show="trust.hover" :src='require("@/assets/img/home/advantage-trust-bg.png")'>
          <img class="logo" v-show="!trust.hover" :src='require("@/assets/img/home/advantage-trust-logo.png")'>
          <img class="logo" v-show="trust.hover" :src='require("@/assets/img/home/advantage-trust-logo-hover.png")'>
          <span class="text">信誉</span>
          <span class="subText">深耕女性盆底市场多年，品牌认知度高，客户信任度高</span>
        </div>
      </div>
    </div>
    <div id="consultation" v-if="!consultationLoading">
      <div class="consultation-top">
        <div class="consultation-title">品牌资讯</div>
        <div class="consultation-list">
          <div class="consultation-item" v-for="(consultationItem, i) in consultation" :key="i"
            @click="goDetail(consultationItem.code)">
            <div class="consultation-item-img"
              :style="{'background-image': 'url('+consultationItem.cover+')'}"></div>
            <div class="consultation-item-title">{{ consultationItem.title }}</div>
            <div class="consultation-item-time">{{ consultationItem.gmt_create }}</div>
          </div>
        </div>
      </div>
      <div class="consultation-more">
        <div @click="goConsultation" class="more-btn">
          <span>了解更多</span>
          <img alt="" src="@/assets/img/about/consultation/down.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css'
import { swiper } from '@/components/swiper'

export default {
  data() {
    return {
      banner: [
        {
          path: require('@/assets/img/home/banner-t20.png'),
          event: () => {this.goProduct('t20')},
        },
        {path: require('@/assets/img/home/banner-0.png')},
        {path: require('@/assets/img/home/banner-1.png')},
        {path: require('@/assets/img/home/banner-2.png')},
        {path: require('@/assets/img/home/banner-3.png')},
        {path: require('@/assets/img/home/banner-4.png')},
      ],
      quality: {
        hover: false,
        top: 0,
      },
      technology: {
        hover: false,
        top: 0,
      },
      service: {
        hover: false,
        top: 0,
      },
      trust: {
        hover: false,
        top: 0,
      },
      consultation: [],
      consultationLoading: true,
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$axios.post(`${this.$request.live}/common/pc/consultation-list`, {page_index: 1, page_size: 3}).then(res => {
      this.consultation = res.data.list
      this.consultationLoading = false
    })
  },
  methods: {
    /**
     页面跳转-产品
     @param {String} route 路由
     @return
     */
    goProduct(route) {
      this.$router.push({path: '/product/' + route})
    },
    /**
     页面跳转-app
     @param
     @return
     */
    goApp() {
      this.$router.push({path: '/app'})
    },
    /**
     鼠标移入公司优势-品质
     @param
     @return
     */
    enterAdvantageQuality() {
      this.quality.hover = true
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.quality.top < -.06) {
          clearInterval(this.interval)
        }
        this.quality.top -= .003
      }, 15)
    },
    /**
     鼠标离开公司优势-品质
     @param
     @return
     */
    leaveAdvantageQuality() {
      this.quality.hover = false
      clearInterval(this.interval)
      this.quality.top = 0
    },
    /**
     鼠标移入公司优势-技术
     @param
     @return
     */
    enterAdvantageTechnology() {
      this.technology.hover = true
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.technology.top < -.06) {
          clearInterval(this.interval)
        }
        this.technology.top -= .003
      }, 15)
    },
    /**
     鼠标离开公司优势-技术
     @param
     @return
     */
    leaveAdvantageTechnology() {
      this.technology.hover = false
      clearInterval(this.interval)
      this.technology.top = 0
    },
    /**
     鼠标移入公司优势-服务
     @param
     @return
     */
    enterAdvantageService() {
      this.service.hover = true
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.service.top < -.06) {
          clearInterval(this.interval)
        }
        this.service.top -= .003
      }, 15)
    },
    /**
     鼠标离开公司优势-服务
     @param
     @return
     */
    leaveAdvantageService() {
      this.service.hover = false
      clearInterval(this.interval)
      this.service.top = 0
    },
    /**
     鼠标移入公司优势-信誉
     @param
     @return
     */
    enterAdvantageTrust() {
      this.trust.hover = true
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.trust.top < -.06) {
          clearInterval(this.interval)
        }
        this.trust.top -= .003
      }, 15)
    },
    /**
     鼠标离开公司优势-信誉
     @param
     @return
     */
    leaveAdvantageTrust() {
      this.trust.hover = false
      clearInterval(this.interval)
      this.trust.top = 0
    },

    goDetail(code) {
      this.$router.push({path: '/about/consultation/detail/' + code})
    },

    goConsultation() {
      this.$router.push({path: '/about/consultation'})
    },
  },
  components: {
    swiper,
  },
}
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 6.6rem;
}

#detail {
  overflow: hidden;

  > .detail-item {
    position: relative;
    margin-top: .08rem;
    height: 7.0rem;
    background-color: #FAFBFF;

    > .title {
      position: absolute;
      z-index: 2;
      line-height: .67rem;
      font-size: .48rem;
      font-weight: bold;
    }

    > .subTitle {
      position: absolute;
      z-index: 2;
      line-height: .45rem;
      font-size: .32rem;
      color: #555;
    }

    > .symbol {
      position: absolute;
      z-index: 2;
      width: 0.44rem;
      height: 0.09rem;
    }

    > .desc {
      color: $md-color-primary;
      position: absolute;
      z-index: 2;
      text-align: left;
      line-height: .33rem;
      font-size: .24rem;
      font-weight: bold;
    }

    > .subDesc {
      position: absolute;
      z-index: 2;
      font-size: .16rem;
      text-align: left;
      line-height: .22rem;
      color: #888;
    }

    > .more {
      position: absolute;
      z-index: 2;
      width: 1.1rem;
      height: .32rem;
      text-align: center;
      line-height: .21rem;
      border-radius: .22rem;
      font-size: .15rem;
      color: #fff;
      background: linear-gradient(to left, $md-color-secondary, $md-color-primary);
      cursor: pointer;
    }

    > .img {
      position: absolute;
      z-index: 1;
    }
  }

  > .detail-item.text-right {
    background: #ffffff;

    > .title {
      top: 1.8rem;
      left: 12.36rem;
    }

    > .subTitle {
      top: 2.51rem;
      left: 12.36rem;
    }

    > .symbol {
      top: 3.16rem;
      left: 12.36rem;
    }

    > .desc {
      top: 4.05rem;
      left: 12.36rem;
    }

    > .subDesc {
      top: 4.42rem;
      left: 12.36rem;
    }

    > .more {
      top: 4.88rem;
      left: 12.36rem;
    }

    > .img {
      top: 0.5rem;
      left: 3.98rem;
      width: 6rem;
      height: 6rem;
    }
  }

  > .detail-item.text-left {
    > .title {
      top: 1.8rem;
      left: 3.6rem;
    }

    > .subTitle {
      top: 2.51rem;
      left: 3.6rem;
    }

    > .symbol {
      top: 3.16rem;
      left: 3.6rem;
    }

    > .desc {
      top: 4.05rem;
      left: 3.6rem;
    }

    > .subDesc {
      top: 4.42rem;
      left: 3.6rem;
    }

    > .more {
      top: 5.0rem;
      left: 3.6rem;
    }

    > .img {
      top: .5rem;
      right: 3.98rem;
      width: 6rem;
      height: 6rem;
    }
  }

  > .detail-item.app {
    background: #ffffff;

    > .title {
      top: 1.81rem;
      left: 11.76rem;
    }

    > .subTitle {
      top: 2.51rem;
      left: 11.76rem;
    }

    > .symbol {
      top: 3.16rem;
      left: 11.76rem;
    }

    > .desc {
      top: 3.98rem;
      left: 11.76rem;
    }

    > .subDesc {
      top: 4.34rem;
      left: 11.76rem;
    }

    > .more {
      top: 4.81rem;
      left: 11.76rem;
    }

    > .img:nth-child(6) {
      top: 1.38rem;
      left: 4.49rem;
      width: 2.25rem;
      height: 4.47rem;
    }

    > .img:nth-child(7) {
      top: 1.38rem;
      left: 7.33rem;
      width: 2.25rem;
      height: 4.47rem;
    }

    > .user {
      position: absolute;
      z-index: 1;
      top: 1.24rem;
      left: 4.5rem;
      width: 2rem;
      height: 4.77rem;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 3.97rem;
      }

      > span {
        color: #333333;
        position: absolute;
        top: 4.28rem;
        left: 0;
        right: 0;
        text-align: center;
        line-height: .25rem;
        font-size: 0.18rem;
        font-weight: bold;
      }

      > em {
        color: #666666;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 0.16rem;
        font-style: normal;
        text-align: center;
        line-height: .22rem;
      }
    }

    > .doctor {
      position: absolute;
      z-index: 1;
      top: 1.24rem;
      left: 7.3rem;
      width: 2rem;
      height: 4.77rem;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
        height: 3.97rem;
      }

      > span {
        color: #333333;
        position: absolute;
        top: 4.28rem;
        left: 0;
        right: 0;
        text-align: center;
        line-height: .25rem;
        font-size: 0.18rem;
        font-weight: bold;
      }

      > em {
        color: #666666;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 0.16rem;
        font-style: normal;
        text-align: center;
        line-height: .22rem;
      }
    }
  }

  > .detail-item.wrightin-t20 {
    > .img {
      top: 0.58rem;
      width: 7.07rem;
      height: 6rem;
      right: 2.87rem;
    }
  }
}

#server {
  position: relative;
  margin-top: .08rem;
  height: 4.03rem;
  background: url(../assets/img/home/server-bg.png) no-repeat center center;
  background-size: 100% 100%;

  > .title {
    position: absolute;
    top: .55rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: .42rem;
    font-size: .3rem;
    color: #fff;
  }

  > .server-content {
    position: absolute;
    top: 1.97rem;
    left: 3.6rem;
    right: 3.6rem;
    display: flex;

    > .server-item {
      width: 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      > strong {
        font-weight: bolder;
        font-size: .48rem;
        color: white;
        display: flex;
        align-items: center;

        > em {
          font-style: normal;
          font-size: .4rem;
          color: white;
          margin-left: .05rem;
        }
      }

      > span {
        margin-top: .1rem;
        text-align: center;
        line-height: .22rem;
        color: #fff;
        word-break: keep-all;
      }
    }
  }
}

#advantage {
  position: relative;
  height: 6.17rem;

  > .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .title {
    position: absolute;
    z-index: 2;
    top: .78rem;
    left: 0;
    right: 0;
    height: .42rem;
    text-align: center;
    line-height: .42rem;
    font-size: .3rem;
  }

  > .content {
    position: absolute;
    z-index: 2;
    top: 2.12rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 12.0rem;
    height: 3.0rem;

    > .item {
      position: absolute;
      top: 0;
      width: 2.85rem;
      height: 3.0rem;
      border-radius: .04rem;
      background-color: #fff;
      cursor: pointer;

      > .bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: .04rem;
      }

      > .logo {
        position: absolute;
        z-index: 2;
        top: .43rem;
        left: .23rem;
        width: .55rem;
        height: .55rem;
      }

      > .text {
        position: absolute;
        z-index: 2;
        top: 1.17rem;
        left: .24rem;
        height: .37rem;
        line-height: .37rem;
        font-size: .26rem;
        letter-spacing: .0108rem;
        font-weight: bold;
      }

      > .subText {
        position: absolute;
        z-index: 2;
        top: 1.74rem;
        left: .24rem;
        right: .24rem;
        height: .24rem;
        line-height: .24rem;
        color: #666;
      }
    }

    > .item.quality {
      left: 0;
    }

    > .item.technology {
      left: 3.05rem;
    }

    > .item.service {
      right: 3.05rem;
    }

    > .item.trust {
      right: 0;
    }

    > .item:hover {
      > .text {
        color: #fff;
      }

      > .subText {
        color: #fff;
      }
    }
  }
}

#consultation {
  animation: fadeInUp 0.5s;
  position: relative;
  height: 6.54rem;
  background: #fff;

  > .consultation-top {
    position: relative;
    height: 5.02rem;
    background: url(../assets/img/home/consultation-bg.png) no-repeat center;
    background-size: 100%;
    padding: 0.7rem 3.6rem 0;

    > .consultation-title {
      font-size: 0.3rem;
      line-height: 0.42rem;
      width: 100%;
      text-align: center;
    }

    > .consultation-list {
      margin-top: 0.71rem;
      display: flex;
      justify-content: space-between;

      > .consultation-item {
        position: relative;
        width: 3.8rem;
        height: 3.2rem;
        background: #fafafa;
        cursor: pointer;

        > .consultation-item-img {
          width: 3.8rem;
          height: 2.18rem;
          background-position: center;
          background-size: cover;
        }

        > .consultation-item-title {
          width: 100%;
          padding: 0 0.12rem;
          font-size: 0.16rem;
          font-weight: bold;
          line-height: 0.22rem;
          margin-top: 0.16rem;
          height: 0.44rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        > .consultation-item-time {
          width: 100%;
          padding: 0 0.12rem;
          font-size: 0.12rem;
          color: #999999;
          line-height: 0.18rem;
          bottom: 0.16rem;
          position: absolute;
        }
      }
    }
  }

  > .consultation-more {
    height: 1.52rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > .more-btn {
      width: 1.2rem;
      height: 0.32rem;
      border-radius: 0.22rem;
      border: 0.01rem solid #999999;
      display: flex;
      justify-content: center;
      cursor: pointer;

      > span {
        color: #333;
        line-height: 0.32rem;
        margin-right: 0.04rem;
      }

      > img {
        width: 0.16rem;
        rotate: 270deg;
      }
    }

    > .more-btn:hover {
      animation: pulse 0.5s infinite;
    }
  }
}
</style>
